import { config } from '../constants';
import { User } from '../types/user';
import axios from 'axios';
import { userApi } from './userApi';

class AuthApi {
  async login({ email, password }): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.API_URL}/auth/local`, {
          identifier: email,
          password,
        })
        .then((response) => {
          resolve(response.data.jwt);
        })
        .catch((error) => {
          console.error('[Auth Api]: ', error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  me(accessToken: string): Promise<User> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.API_URL}/users/me?populate=*`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then((response) => {
          const me: User = response.data;

          // modify some entries of the me to be suitable to our
          // application needs.
          me.role = userApi.roleObj(response.data.role);

          if (me.avatar) {
            me.avatar.url = userApi.imageUrl(me.avatar);
          }

          if (me.projectlogo) {
            me.projectlogo.url = userApi.imageUrl(me.projectlogo);
          }

          resolve(me);
        })
        .catch((error) => {
          console.error('[Auth Api]: ', error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  resetPassword(code: string, password: string, passwordConfirmation: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.API_URL}/auth/reset-password`, {
          code,
          password,
          passwordConfirmation,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('[Auth Api]: ', error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  forgotPassword(email: string) : Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.API_URL}/auth/forgot-password`, {
          email
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('[Auth Api]: ', error.response);
          reject(new Error('Internal server error'));
        });
    });
  }
}

export const authApi = new AuthApi();
