import { config } from '../constants';
import { User } from '../types/user';
import { UserRoleEnum } from '../types/role';
import axios from 'axios';
import { ProjectFile } from '../types/project';

class UserApi {
  async getUsers(): Promise<User[]> {
    const accessToken = window.localStorage.getItem('accessToken');

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.API_URL}/users?populate=*`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('[User Api]: ', error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  async getUser({ id }): Promise<User> {
    const accessToken = window.localStorage.getItem('accessToken');

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.API_URL}/users/${id}?populate=*`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('[User Api]: ', error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  async update(id: number, data: object): Promise<User> {
    const accessToken = window.localStorage.getItem('accessToken');

    return new Promise((resolve, reject) => {
      axios
        .put(`${config.API_URL}/users/${id}?populate=*`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('[User Api]: ', error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  async register(data: object): Promise<string> {
    const accessToken = window.localStorage.getItem('accessToken');

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.API_URL}/register`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          resolve(response.data.jwt);
        })
        .catch((error) => {
          console.error('[Auth Api]: ', error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  async delete(id : number): Promise<void> {
    const accessToken = window.localStorage.getItem('accessToken');

    return new Promise((resolve, reject) => {
      axios
        .delete(`${config.API_URL}/users/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          resolve(response.data.data !== undefined ? response.data.data : response.data);
        })
        .catch((error) => {
          console.error('[User Api]: ', error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  async resetPassword(password: string, passwordConfirm: string): Promise<string> {
    const accessToken = window.localStorage.getItem('accessToken');

    const data = {
      newPassword: password,
      confirmPassword: passwordConfirm,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.API_URL}/password`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          resolve(response.data.jwt);
        })
        .catch((error) => {
          console.error('[User Api]: ', error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  imageUrl(image: ProjectFile) {
    let url: string = null;

    if (image) {
      url = config.API_URL.concat(image.url);
    }

    return url;
  }

  roleObj(role: any) {
    return { id: role.id, name: role.name, type: role.type };
  }

  toUserRoleEnum(role: string) {
    if (role === 'Admin') {
      return UserRoleEnum.Admin;
    }

    if (role === 'Employee') {
      return UserRoleEnum.Employee;
    }

    if (role === 'Author') {
      return UserRoleEnum.Author;
    }

    return UserRoleEnum.Company;
  }
}

export const userApi = new UserApi();
