import type { FC } from 'react';
import type { Theme } from '@mui/material';
//  import { experimentalStyled } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { ReactComponent as Logofile } from '../theme/Logo_CSR.svg';

interface LogoProps {
  sx?: SxProps<Theme>;
}

// const LogoRoot = experimentalStyled('svg')``;

const Logo: FC<LogoProps> = (props) => (
  <Logofile
    height="100"
    version="1.1"
    viewBox="0 0 252 97.1"
    width="185"
    {...props}
  />
);

export default Logo;
